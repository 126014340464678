import { useContext, useState, useEffect } from "react";
import { UserContext } from "../components/context/userContext";
import { useSafeAsync } from "../components/useSafeAsync";
import { Timestamp, addDoc, collection, doc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "../components/firebase-public";
import { AddCircle, Close, Done, ExpandMore, Help, LinkOff } from "@mui/icons-material";
import { Stack, Grid, Typography, IconButton, Box, Link, Paper, Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Tooltip, useMediaQuery } from "@mui/material";
import {Link as LinkIcon} from "@mui/icons-material"
import LoadingGuard from "../components/loadingGuard";
import { CheckboxElement, DateTimePickerElement, FormContainer, TextFieldElement } from "react-hook-form-mui";
import { cs } from "date-fns/locale";
import DateFnsProvider from "../components/DateFnsProvider";
import { getFunctions, httpsCallable } from "firebase/functions";
import DateCalendarAkce from "../components/calendar";

const functions = getFunctions();
const sendNotification = httpsCallable(functions, 'notifications-send');



async function getAkce() {
  const date = new Date(); 
  //date.setHours(date.getHours() - 1); // max 1 hodinu zpatky
  date.setHours(date.getHours() - 1); // max 1 hodinu zpatky
  const akceColl = collection(db, 'akce');

  //TODO - offline caching? - asi nema vyznam kvuli aktualnim datum
  
  const akceSnapshot = await getDocs(
    query(akceColl, where('datum', '>=', date), orderBy('datum','desc'))
  );

  if (!akceSnapshot.empty) {
    return akceSnapshot.docs.map((doc) => {return {id:doc.id,...doc.data()}} );
  }
  return [];
}

async function AddNew(data:any) {
  const date = new Date()
  if (data.datum < date){window.location.reload(); return;}

  var hlasovani:{} = {
    hlasovani: false
  }

  if (data.hlasovani) {
    hlasovani = {
      hlasovani: true,
      hlasovani_pro: [],
      hlasovani_proti: []
    }
  }
  var final = {
    nazev: data.nazev,
    link: (data.link)?data.link:"",
    popis: (data.popis)?data.popis:"",
    datum : Timestamp.fromDate(data.datum),
    osoby: [],
    ...hlasovani
  }

  const collRef = await collection(db,'akce');
  addDoc(collRef,final).then(async ()=>{
    const datum = `v ${data.datum.toLocaleString("cs-CZ",{weekday:"long"})} ${data.datum.toLocaleDateString("cs-CZ")} v ${data.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}`;
    await sendNotification({title:"Byla přidána nová akce", text: `Nová akce s názvem ${data.nazev} ${datum}`, url: `https://ravens-regiment.com/akce`})
    console.log("should be send the notif");
    window.location.reload()
  });
}

async function SaveUser(id:string,prev:any[],user:string) {
  const data = {
    osoby: [...prev,user]
  }

  const akceRef = doc(db,'akce',id)
  setDoc(akceRef, data, { merge: true }).then(()=>{window.location.reload()});
  
}

async function SaveVote(data:any,answer:string,user:string) {
  var newData:any = {}
  if (answer === "yes") {
    newData.hlasovani_pro = [...data.hlasovani_pro,user] 
  } else {
    newData.hlasovani_proti = [...data.hlasovani_pro,user] 
  }
  newData['osoby'] = [...data.osoby,user] 
  
  const akceRef = doc(db,'akce',data.id)
  setDoc(akceRef, newData, { merge: true }).then(()=>{window.location.reload()});
  
}

export default function AkcePage(props:any) {
  const [akceCall, akceState] = useSafeAsync<any,any>(getAkce);
  const [loader, setLoader] = useState(false);
  const userObject = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
      akceCall({});
  },[])


  if (loader) {
    return(<>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    </>)
  }
  

  return(<>
  <LoadingGuard state={akceState} >
        {akceState.value && userObject &&
        <>
        <Grid container direction={"row"} spacing={2} p={(isNotPhone)?4:2}>
          <Grid item xs={12}>
            <DateCalendarAkce />
          </Grid>
          {(userObject.isAdmin)?
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="vlozit-novy"
                id="vlozit-novy"
              >
                <Typography>Přidat novou</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <NewNote onFormSubmit={AddNew} setLoader={setLoader}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
          :<></>}

          {akceState.value.map((val:any,i:number)=>{
              return(
              <OneNote key={"note-"+i} data={val} setLoader={setLoader}/>
              );
            })
          }
        </Grid>
        </>
        }
    </LoadingGuard>
  </>)
}


function OneNote(props:any) {
  const data = props.data;
  const userObject = useContext(UserContext);
  const datum = new Timestamp(data.datum.seconds,data.datum.nanoseconds).toDate();
  const onClick = () => {
    if(userObject && userObject.userName){
      SaveUser(data.id,data.osoby,userObject.userName);
      props.setLoader(true)
    }
  }
  const onVote = (answer:string) => {
    if(userObject && userObject.userName){
      SaveVote(data,answer,userObject.userName);
      props.setLoader(true)
    }
  }

  var ucast = "";
  if (data.hlasovani) {
    data.hlasovani_pro.forEach((clen:string) => {
      ucast = clen + "," + ucast;
    })
    data.hlasovani_proti.forEach((clen:string) => {
      ucast = clen + "," + ucast;
    })
  } else {
    if(data.osoby[1]){
      data.osoby.forEach((clen:string) => {
        ucast = clen + "," + ucast;
      });
    }else{
      ucast = data.osoby[0];
    }
  }
  


  return(<>
  <Grid item xs={12} sm={6} xl={3}>
    <Stack direction={'column'} px={2} py={1} spacing={0.5} component={Paper}>
      <Typography variant="h6" textAlign={"center"}>{data.nazev}</Typography>
      <Typography variant="body2" textAlign={"center"}>{datum.toLocaleTimeString('cs-CZ')}</Typography>
      <Typography variant="body2" textAlign={"center"}>{datum.toLocaleDateString('cs-CZ',{weekday:'long'})} {datum.toLocaleDateString('cs-CZ')}</Typography>
      <Stack direction={"row"} justifyContent={"space-evenly"}>
        {(data.link)?
          <Tooltip title={data.link}><Link target="_blank" href={data.link}><IconButton><LinkIcon /></IconButton></Link></Tooltip>
          :<Tooltip title={"Není odkaz"}><IconButton><LinkOff /></IconButton></Tooltip>}
        {(data.osoby && !(data.osoby.includes(userObject!.userName)) && !(data.hlasovani))?
        <Tooltip title={"Přidat účast"}><IconButton onClick={onClick}><AddCircle /></IconButton></Tooltip>
        :<Tooltip title={ucast}><IconButton><Help /></IconButton></Tooltip>}
      </Stack>
      {(data.popis)?<Typography variant="body2">{data.popis}</Typography>:<></>}
      {(data.hlasovani)?
        <Stack direction={"row"} justifyContent={"space-evenly"} alignItems={"center"}>
          <Tooltip title={data.hlasovani_pro.length}><IconButton onClick={()=>{if(!(data.hlasovani_pro.includes(userObject!.userName)) && !(data.osoby.hlasovani_proti(userObject!.userName)))onVote("yes")}}><Done color="success" /></IconButton></Tooltip>
          <Tooltip title={data.hlasovani_proti.length}><IconButton onClick={()=>{if(!(data.hlasovani_pro.includes(userObject!.userName)) && !(data.osoby.hlasovani_proti(userObject!.userName)))onVote("no")}}><Close color="error" /></IconButton></Tooltip>
        </Stack>
      :<></>}
    </Stack>
  </Grid>
  </>)
}

function NewNote(props:any) {
  const [loading, setLoading] = useState(false);

  return(<>
  <FormContainer
    onSuccess={(data)=>{setLoading(true); props.onFormSubmit(data);}}
    defaultValues={{
      datum: new Date(),
    }}
  >
    <Stack direction={"column"} spacing={1}>
      <TextFieldElement label={"Název"} name={"nazev"} />
      <DateFnsProvider adapterLocale={cs}><DateTimePickerElement label="Datum" name="datum" /></DateFnsProvider>
      <TextFieldElement label={"Odkaz"} name={"link"} />
      <TextFieldElement label={"Popis"} name={"popis"} />
      <CheckboxElement label={"Zapnout hlasování?"} name={"hlasovani"} />

      <Box sx={{display:"flex",justifyContent:"center"}}>{(loading)?<IconButton aria-label="delete" disabled color="primary"><CircularProgress /></IconButton>:<Button type="submit" variant="outlined">Přidat novou!</Button>}</Box>
    </Stack>
  </FormContainer>
  </>)
}
