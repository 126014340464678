import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, Stack, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../components/context/userContext";
import { useSafeAsync } from "../components/useSafeAsync";
import { collection, doc, getDoc, getDocs} from "firebase/firestore";
import { db } from "../components/firebase-public";
import LoadingGuard from "../components/loadingGuard";
import { CustomObjectSort } from "../components/helpers";
import DateCalendarAkce from "../components/calendar";


export default function MemberSectionHomePage(props:any) {
    const [expanded,setExpanded] = useState('');
    const handleChange = (panel:string)=>()=>{(expanded !== panel)?setExpanded(panel):setExpanded('')}
    const isNotPhone = useMediaQuery('(min-width:600px)');


    return (<>
    <Stack direction={"column"} flexWrap={"wrap"} p={(isNotPhone)?4:1} spacing={1}>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <DateCalendarAkce />
      </Box>

      <Accordion expanded={expanded === 'moje-kurzy'} onChange={handleChange('moje-kurzy')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="moje-kurzy"
          id="moje-kurzy"
        >
          <Typography>Moje kurzy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListKurzy expanded={expanded === 'moje-kurzy'} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'moje-mise'} onChange={handleChange('moje-mise')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="moje-mise"
          id="moje-mise"
        >
          <Typography>Moje mise</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListMissions  expanded={expanded === 'moje-mise'} />
        </AccordionDetails>
      </Accordion>
    </Stack>
    </>);
}

// --------------------- KURZY ----------------------------------

async function LoadKurzy({username}:{username:string}) {
  var min_hodnosti:any = {}
  const kurzyColl = doc(db,'kurzy','podle_hodnosti');
  const kurzySnap = await getDoc(kurzyColl);
  if (kurzySnap.exists()) min_hodnosti = kurzySnap.data();

  const userColl = doc(db,'clenove',username);
  var clen:any = {}
  
  const userSnap = await getDoc(userColl);
  if (userSnap.exists()) clen = userSnap.data();

  if(!clen) return null;

  const hodnost = clen.hodnost_order;
  Object.keys(clen.kurzy).forEach((key)=>{
    if(clen.kurzy[key] == 0 && min_hodnosti[key] <= hodnost){ // == protoze 0 ale i '0'
      clen.kurzy[key] = '3';
    }else if (clen.kurzy[key] == 0){ 
      clen.kurzy[key] = '4';
    }
    clen.kurzy[key] = clen.kurzy[key].toString();
  });
  return CustomObjectSort(clen.kurzy)
}

function ListKurzy(props:any) {
  const [kurzyCall, kurzyState] = useSafeAsync<any,any>(LoadKurzy);
  const userObj = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    if (userObj?.userName) {
      kurzyCall({username:userObj.userName})
    }
  },[userObj])

  return (<>
    <LoadingGuard state={kurzyState}>
      {kurzyState.value && <>
        <Typography variant="caption" color={"#0f4d1c"} mr={1}>Splněný kurz</Typography>
        <Typography variant="caption" color={"#6c6c0c"} mr={1}>Instruktor</Typography>
        <Typography variant="caption" color={"#1a627d"} mr={1}>Má hodnost pro kurz</Typography>
        <Typography variant="caption" color={"#6c0c0c"}>Nemůže dělat kurz</Typography>
        <Grid container spacing={1} gap={(isNotPhone)?1:2} justifyContent={(isNotPhone)?"flex-start":"space-evenly"} mt={1}>
          {Object.keys(kurzyState.value).map((key:any, i:any)=>{
            let style={}
            switch (kurzyState.value[key]) {
              case '1': // uz ma kurz
                style={background:'#0f4d1c'}
                break;
              case '2': // je instruktor
                style={background:'#6c6c0c'}
                break;
              case '3': // muze udelat
                style={background:'#1a627d'}
                break;
              case '4': //nemuze udelat
                style={background:'#6c0c0c'}
                break;
            
              default: // 0 - nema kurz
                style={background:'#6c0c0c'}
                break;
            }
            return(
              <Grid key={key} item xs={3} md={1.8} sx={{padding:"0 !important",...style}} justifyContent={"space-evenly"}>
                <Link href={"/pro-cleny/hledani-kurzu/"+key} style={{ textDecoration: 'none' }}><Typography variant="body2" sx={{textAlign:'center'}} p={1}>{key.toUpperCase()}</Typography></Link>
              </Grid>
            )
          })}
        </Grid>
      </>}
    </LoadingGuard>
  </>);
}

// --------------------- MISE ------------------------------
async function LoadMissions({username}:{username:string}) {
  const missionsColl = collection(db,'clenove',username,'mise');
  const missionsSnap = await getDocs(missionsColl);
  if (! missionsSnap.empty) {
    const all:Record<string,any> = {}
    missionsSnap.forEach((doc)=>{
      const data = doc.data();
      all[doc.id] = data;
    })
    return all;
  }
}

function MissionLine({title, data, deployment}:{title:string, data:any, deployment?:string}) {
  const isNotPhone = useMediaQuery('(min-width:600px)');

  if(!data || Object.keys(data).length === 0) return <></>

  return(
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="h4">{title}</Typography>
    </Grid>
    {Object.keys(data).map((id:string)=>{

      return(
        <Fragment key={id}>
          <Grid item xs={(isNotPhone)?6:12} mt={(isNotPhone)?0:1}><Link href={(deployment)?`/deployment/${deployment}/${id}`:`/mise/${id}`} >{id}</Link></Grid>
          <Grid item xs={(isNotPhone)?2:12}>{data[id].platoon}</Grid>
          <Grid item xs={(isNotPhone)?2:4}>{data[id].slot}</Grid>
          <Grid item xs={(isNotPhone)?2:4}>{data[id].kit}</Grid>
        </Fragment>
      )
    })}
    <Grid item xs={12}><Divider /></Grid>
  </Grid>
  )
}

function ListMissions(props:any) {
  const [missionsCall, missionsState] = useSafeAsync<any,any>(LoadMissions);
  const userObj = useContext(UserContext);
  const isNotPhone = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    if (userObj?.userName) {
      missionsCall({username:userObj.userName})
    }
  },[userObj])

  return (
    <LoadingGuard state={missionsState}>
      {missionsState.value &&
        <Stack direction={"column"} spacing={2}>
          <MissionLine title="Oficiální" data={missionsState.value.oficialni} />
          <MissionLine title="Neoficiální" data={missionsState.value.neoficialni} />
          <MissionLine title="Tréninky" data={missionsState.value.trenink} />
          {Object.keys(missionsState.value).map((key)=>{
            if (key !== "neoficialni" && key !== "oficialni" && key !== "trenink") {
              return(<MissionLine key={key} title={key} data={missionsState.value[key]} deployment={key} />)
            }
          })}
        </Stack>
      }
    </LoadingGuard>
  )
}