import { useContext, useState, useEffect } from "react";
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import DateFnsProvider from './DateFnsProvider';
import cs from 'date-fns/locale/cs';
import LoadingGuard from './loadingGuard';
import { useSafeAsync } from './useSafeAsync';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from './firebase-private';
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

async function getAkce({year, month}:{year:number, month:number}) {
  const startOfMonth = new Date(year, month - 1, 1);
  const endOfMonth = new Date(year, month, 0, 23, 59, 59);
  const allData = [];

  // 1. Načíst z kolekce "akce"
  const akceColl = collection(db, 'akce');
  const akceSnapshot = await getDocs(
    query(
      akceColl,
      where('datum', '>=', startOfMonth),
      where('datum', '<=', endOfMonth),
      orderBy('datum', 'asc')
    )
  );

  if (!akceSnapshot.empty) {
    allData.push(...akceSnapshot.docs.map((doc) => {
        const data = doc.data();
        return { id: doc.id, datum: data.datum.toDate(), nazev: data.nazev, typ:'a' , source: 'akce' }
      }
    ));
  }

  // 2. Načíst z kolekce "mise"
  const miseColl = collection(db, 'mise');
  const miseSnapshot = await getDocs(
    query(
      miseColl,
      where('datum', '>=', startOfMonth),
      where('datum', '<=', endOfMonth),
      orderBy('datum', 'asc')
    )
  );
  if (!miseSnapshot.empty) {
    allData.push(...miseSnapshot.docs.map((doc) => {
      const data = doc.data();
      return { id: doc.id, datum: data.datum.toDate(), nazev: data.nazev, typ: data.typ, source: 'mise' }
    }));
  }



  // 3. Načíst z kolekce "deplomenty" -> jejich podkolekce "mise"
  const deploymentyColl = collection(db, 'deploymenty');
  const deploymentySnapshot = await getDocs(query(deploymentyColl, where('datum_do', '>=', endOfMonth), orderBy("datum_do","asc")));

  for (const deplomentDoc of deploymentySnapshot.docs) {
    const miseSubColl = collection(deplomentDoc.ref, 'mise');
    const subMiseSnapshot = await getDocs(
      query(
        miseSubColl,
        where('datum', '>=', startOfMonth),
        where('datum', '<=', endOfMonth),
        orderBy('datum', 'asc')
      )
    );
    if (!subMiseSnapshot.empty) {
      allData.push(
        ...subMiseSnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, datum: data.datum.toDate(), nazev: data.nazev, typ: 'd', deployment: deplomentDoc.id, source: 'deplomenty' }
        })
      );
    }
  }

  return allData;
}

function ServerDay(props:any) {
  const { day, outsideCurrentMonth, akceData, ...other } = props;
  const Navigate = useNavigate();

  const hasEvent = akceData.some((event:any) => {
    const eventDate = new Date(event.datum).toDateString();
    return eventDate === day.toDateString();
  });

  let badgeContent:string|undefined = undefined;
  let badgeColor = undefined;
  let badgeTooltip = undefined;
  let url: string|undefined = undefined;
  if (hasEvent && !props.outsideCurrentMonth) {
    Object.keys(akceData).forEach((index)=>{
      const one = akceData[index];
      const eventDate = new Date(one.datum).toDateString();
      if (eventDate === day.toDateString()) {
        badgeContent = one.typ.toUpperCase();
        badgeTooltip = `${one.nazev} - ${one.datum.toLocaleTimeString("cs-CZ").slice(0,-3)}`;

        switch (one.typ) {
          case 'd':
            badgeColor = "success";
            url = `/deployment/${one.deployment}/${one.id}`;
            break;
          case 'o':
            badgeColor = "secondary";
            url = `/mise/${one.id}`;
            break;
          case 'n':
            badgeColor = "error";
            url = `/mise/${one.id}`;
            break;
          case 't':
            badgeColor = "warning";
            url = `/mise/${one.id}`;
            break;
        
          default:
            badgeColor = "primary"
            break;
        }
      }
    })
  }

  return (
    <Tooltip title={badgeTooltip} placement="top" arrow >
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={badgeContent}
        color={badgeColor}
      >
        <PickersDay 
          {...other}
          outsideCurrentMonth={outsideCurrentMonth} 
          day={day} 
          selected={false}
          onDaySelect={()=>{
            if (url) Navigate(url);
          }} 
        />
      </Badge>
    </Tooltip>
  );
}

export default function DateCalendarAkce() {
  const [akceCall, akceState] = useSafeAsync<any,any>(getAkce);
  const [datum,setDatum] = useState(new Date());

  useEffect(()=>{
    akceCall({year:datum.getFullYear(), month:datum.getMonth() + 1});
  },[datum])

  const handleMonthChange = (date: any) => {
    setDatum(date);
  };

  return (
    <LoadingGuard state={akceState} >
      {akceState.value &&
        <DateFnsProvider adapterLocale={cs}>
          <DateCalendar
            value={datum}
            views={['day']}
            readOnly
            renderLoading={() => <DayCalendarSkeleton />}
            onMonthChange={handleMonthChange}
            slots={{
              day: (props) => <ServerDay {...props} akceData={akceState.value} />,
            }}
            slotProps={{
              ...akceState.value
            }}
          />
        </DateFnsProvider>
      }
    </LoadingGuard>
  );
}